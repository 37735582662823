import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
/* eslint-disable */
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
export function export_txt_to_zip(th, jsonData, txtName, zipName) {
  var zip = new JSZip();
  var txt_name = txtName || 'file';
  var zip_name = zipName || 'file';
  var data = jsonData;
  var txtData = "".concat(th, "\r\n");
  data.forEach(function (row) {
    var tempStr = '';
    tempStr = row.toString();
    txtData += "".concat(tempStr, "\r\n");
  });
  zip.file("".concat(txt_name, ".txt"), txtData);
  zip.generateAsync({
    type: "blob"
  }).then(function (blob) {
    saveAs(blob, "".concat(zip_name, ".zip"));
  }, function (err) {
    alert('导出失败');
  });
}